export default {
  Preorder: {
    Pellet: '颗粒',
    PreorderID: '预约商品ID',
    PreorderCode: '产品编码',
    Weight: '重量',
    PurchaseRange: '上限',
    PriceJPY: '价格(JPY)',
    PriceUSD: '价格(USD)',
    Period: '预约期间',
  },
  Auction: {
    Pellet: '颗粒',
    AuctionID: '竞价产品ID',
    Weight: '重量',
    Code: '产品编码',
    MinAmount: '最低金额',
    MinJPY: '最低金额(JPY)',
    MinUSD: '最低金额(USD)',
    Period: '期间',
  },

  Quotation: {
    Quotation: '报价单',
    No: 'No.',
    QuotationDate: '报价日',
    Description: '请参考以下的报价。',
    FinalAmount: '报价金额',
    BidAmount: '竞价金額',
    BidUnit: '套',
    Subtotal: '小计',
    Discount: '优惠',
    Total: '合计',
    Code: 'No.',
    Date: 'Date',
    ItemInfo: '商品情报',
    Qty: '数量',
    Unit: '单位',
    Price: '单价',
    Weight: '重量',
    Amount: '金额',
    Note: '备注',
    Expiration: '有效期限',
  },
  GreenEarth: {
    CompanyName: '株式会社格林亚斯',
    Address: '日本国茨城县坂东市猫实1679番1',
  },
}
